import { Link } from 'react-router-dom';
import SecondaryButton from '../../components/Button/SecondaryButton';
import RetailerModal from '../RetailerModal/RetailerModal';


const ProductContent = ({ image, title,  cta, productLink}: { image: string,  title: string, cta?:string, productLink?:any}) => {
  return (
    <div className='flex flex-col items-center justify-center gap-1 pb-5 px-5 overflow-hidden opacity-90 z-20'>
      <img src={image} alt={title} style={{ maxHeight: "13rem", minHeight: "4rem", maxWidth: "13rem", minWidth: "4rem"}}/>
      <p className="text-center text-[#005D6E]" style={{ fontFamily: 'Hurme Geometric Sans 3', fontWeight: 500, fontSize: '16px',
         lineHeight: '20px', letterSpacing: '0px', color: '#005D6E', opacity: 1 }}>
        {title}
      </p>
      {/* <p>{rating}</p> */}
      {/* <p className="text-center text-[#005D6E]" style={{ fontFamily: 'Hurme Geometric Sans 3', fontWeight: 600, fontSize: '25px', lineHeight: '30px' }}>
        {`R ${price}`}
      </p> */}
    
    <Link to={productLink}>
                         <SecondaryButton label={cta || 'STORE LOCATOR'}/>
                         </Link>
            
  
    </div>
  );
};

export default ProductContent;