import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import { Images } from "../../assets/assets";
import Footer from "../../components/Footer/Footer";
import Product from "../../components/Product/Product";
import ProductContent from "../../components/ProductContent/ProductContent";
import { Link } from "react-router-dom";
import { BiChevronDown } from "react-icons/bi";
import { AccessoriesClass } from "../../APIs";
import Loading from "../../components/Loading/Loading";
import { Helmet } from "react-helmet";
interface Access {
  id?: number;
  banner: string;
  name: string;
  description: string;
  caption: string;
  ingredient: string;
  price: number;
  rating: number;
  image: string;
  accessorylink:string
}

const AccessoriesPage = () => {
  const [accessories, setAccessories] = useState<Access[]>([]);

  useEffect(() => {
    fetchAccData();
  }, []);

  function toUrlSlug(text: string) {
    const decoded = decodeURIComponent(text);
    const hyphenated = decoded.replace(/\s+/g, '-');
    return hyphenated;
  }
  const fetchAccData = async () => {
    try {
      const { data, error } = await AccessoriesClass.getAllAccessories();

      if (data) {
        setAccessories(data);
      } else {
        console.error("Error fetching acc:", error);
      }
    } catch (error) {
      console.error("Error fetching acc:", error);
    }
  };

  const capitalizeFirstLetter = (str:string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {"Soda Bottle & Sparkling Water Maker Accessories | SodaStream SA"}
          </title>
          <meta
            name="description"
            content={
              "SodaStream offers a selection of soda bottles and sparkling water maker accessories to help you easily make your own perfect bubbly sparkling water at home."
            }
          />
            <link rel="canonical" href={"https://www.sodastream.co.za/accessories"} />
        </Helmet>
        {accessories ? (
          <>
            <div>
              <Header
                title="Sparkling Water Maker Accessories"
                textBackground="ACCESSORIES"
                backgroundImg={Images.AccessoriesBg}
              />

              <div className="relative md:min-h-[10rem] w-full mt-[-10%]">
                <div className="md:relative gap-10 z-50 flex flex-col items-center justify-center w-full">
                  {/* <FilterBar /> */}
                  <div className="my-5">
                    <div className="grid md:grid-cols-3 grid-cols-1 lg:grid-cols-4 gap-10 px-10">
                      <div className="md:block hidden">
                        <Product>
                          <div className="flex items-center justify-center">
                            <p className="text-[#75A7AD] text-start text-[30px] p-20">
                              Grab a Bottle
                            </p>
                          </div>
                        </Product>
                      </div>
                      {accessories.map((acc, index) => (
                        <div key={index}>
                            <Product>
                              <ProductContent
                              productLink={`/accessories/${toUrlSlug(acc.accessorylink)}`}
                                cta="View Product"
                                title={capitalizeFirstLetter(acc.name)}
                                image={acc.image}
                              />
                            </Product>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};

export default AccessoriesPage;