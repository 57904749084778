import React, { useState } from "react";
import { Modal } from "antd";
import { Link } from "react-router-dom";
const RetailerModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const logos = [
    "http://res.cloudinary.com/brabenjos/image/upload/v1723364757/sodastream/1723364757097.png",
    "http://res.cloudinary.com/brabenjos/image/upload/v1723364868/sodastream/1723364868127.png",
    "http://res.cloudinary.com/brabenjos/image/upload/v1723453555/sodastream/1723453555356.png",
    "http://res.cloudinary.com/brabenjos/image/upload/v1723364928/sodastream/1723364928860.png",
  ];

  const buttons = [
    { label: "View all products", link: "https://www.checkers.co.za/search?q=sodastream" },
  
    { label: "View all products", link: "https://www.pnp.co.za/b/SODA%20STREAM" },
    { label: "View all products", link: "https://www.takealot.com/all?filter=Brand:Sodastream" },
    { label: "View all products", link: "https://m.yuppiechef.com/sodastream.htm" },
   
   
  ];

  return (
    <>
     <button
  onClick={showModal}
  className="w-[137px] h-[27px] bg-white border border-[#005D6E] rounded-[46px] flex items-center justify-center cursor-pointer"
>
  <span className="text-[#005D6E] text-[12px] leading-[29px] uppercase font-bold">
    RETAILERS
  </span>
</button>


      <Modal
     
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null} 
      >
       <h1 className="box-border 
        text-[#005D6E] block font-poppins text-[19px] font-semibold  leading-[38.4px]">
       Find a retailer
        </h1>
        <div>
       
       
      
        <h1 className="box-border text-center 
        text-[#005D6E] block font-poppins text-[18px] font-semibold  leading-[38.4px] mt-[20px]">
       Shop Online at the following retailers:
        </h1>
      </div>

      <div className="grid grid-cols-1 gap-4">
  {logos.map((logo, index) => (
    <div
      key={index}
      className="flex items-center justify-between rounded-lg shadow-sm h-[70px] w-full p-4"
    >
      <img
        src={logo}
        alt={`Retailer Logo ${index + 1}`}
        className="max-w-[100px] max-h-[100px] object-contain mr-4"
      />
      <Link to={buttons[index].link}>
        <button
          className="w-full p-4 sm:w-[150px] md:w-[200px] h-[40px] text-sm sm:text-base md:text-lg text-white rounded-full bg-[#75A7AD] 
            flex items-center justify-center hover:bg-[#005D6E] focus:outline-none
            px-3 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3"
        >
          {buttons[index].label}
        </button>
      </Link>
    </div>
  ))}
</div>

      </Modal>
    </>
  );
};

export default RetailerModal;